import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../css/Login.css';
import '../css/App.css';
import { ReactComponent as Logo } from "../images/KogoFlower.svg";
import useRefreshToken from "../hooks/useRefreshToken";
import {createTheme} from "../scenes/theme";
import useNav from '../hooks/useNav';


const USER_REGEX = /^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/;
const PWD_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

const Login = () => {
    const userRef = useRef();
    const errRef = useRef();
    const refresh = useRefreshToken();

    const { auth, setAuth } = useAuth();
    const {setPath} = useNav();
    const location = useLocation();

    const [username, setUsername] = useState();
    const [pwd, setPwd] = useState();

    const [validUsername, setValidUsername] = useState();
    const [validPwd, setValidPwd] = useState();

    const [usernameFocus, setUsernameFous] = useState();
    const [pwdFocus, setPwdFocus] = useState();

    const [errorMsg, setErrorMsg] = useState(false);


    useEffect(() => {
        if(auth?.access_token === undefined){ //redirect user if they are logged in.
            refresh().then(res => {
                setPath('/rules');
            },
            (error) => {
            }
            );
        }
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
    }, [pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const response = await axios.post('https://s2.transactionmonitor.co.uk/login-jwt',
                { username: username, password: pwd },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const { access_token } = await response.data;
            setAuth({ access_token });
            setPath('/dashboard');
        }
        catch (error) {
            console.error(error);
            const status = error.response.status;
            if (status === 403) {
                setErrorMsg('Invalid username or password');
            }
            else {
                setErrorMsg('Something went wrong. Please try again');
            }
        }
    };

    const getLoginButtonClass = () =>{
        let buttonClass = "login-button"
        return (username && pwd) ? buttonClass + " login-button-enabled" : buttonClass + " login-button-disabled";  
    }

    useEffect(() => {
    }, [auth])

    return (
        <div>
            <div className='login-div'>
                <section className='login-section'>
                    <div style={{ textAlign: "center" }}>
                        <Logo />
                    </div>
                    <div>
                        <p className="login-title">KogoPay Transaction Monitoring</p>
                    </div>
                    <p id="login-error" className={errorMsg ? "instructions" : "offscreen"} aria-live="assertive">{errorMsg}</p>
                    <form className='login-form' onSubmit={handleSubmit}>
                        <input
                            className='login-height'
                            type="username"
                            name="username"
                            placeholder="Username"
                            value={username}
                            ref={userRef}
                            onChange={(e) => { setUsername(e.target.value) }}
                            required
                            aria-invalid={validUsername ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUsernameFous(true)}
                            onBlur={() => setUsernameFous(false)}
                        />
                        
                        <input
                            className='login-height'
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}

                        />
                        <button className={getLoginButtonClass()} disabled={!username || !pwd ? true : false} type="submit">Login</button>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default Login;