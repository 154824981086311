import React from 'react';
import Customers from './Customers';

const ProfileSuggestionsPopup = ({onCancel, suggestedCustomers, onSelect }) => {

  return (
    <div className="confirm-popup-parent">
      {/* Popup content */}
      <div className="popup-content">
        <h3>Customers found</h3><br/>

        {suggestedCustomers.length === 0 &&
          <p>No customers found</p>
        }
        <ul className="lookup-ul" style={{position: "relative", width: "500px"}}>
          {suggestedCustomers.map((customer, i) => <li style={{ cursor: "pointer" }} onClick={() => { onSelect(customer) }}><p>{customer.customer_code} {customer.name} {customer.sname}</p></li>)}
        </ul>
        {/* Buttons */}
        <div className="buttons">
          <button onClick={() => { onCancel() }} className='popup-button popup-button-cancel'>Close</button>
        </div>
      </div>
    </div>
  );
}

export default ProfileSuggestionsPopup;