import React, { useState, useEffect } from "react";
import "../css/Whitelist.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useOrientation from "../hooks/useOrientation";

//In edit rule, get the whitelist and pass it here. Also pass the customers here and filter
const Whitelist = ({ whitelist, onWhitelistAdd, onWhitelistDelete }) => {
    const {isPortrait} = useOrientation();
    const [whitelistFocus, setWhitelistFocus] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [whitelistQuery, setWhitelistQuery] = useState();
    const [customersFiltered, setCustomersFiltered] = useState([]);
    const [customers, setCustomers] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    const [whitelistFiltered, setWhitelistFiltered] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getCustomers = async () => {
            try {
                const response = await axiosPrivate.get('/get-customers-for-whitelist', { signal: controller.signal });
                if (isMounted) {
                    const data = response.data;
                    setCustomers(data);
                }
            }
            catch (error) {
                console.error(error);
            }
        }
        getCustomers();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setWhitelistFiltered(whitelist);
        setWhitelistQuery('');
    }, [whitelist]);


    const filterCustomers = (query) => {
        setSearchValue(query);
        if (query === "") {
            setCustomersFiltered([]);
            return;
        }
        var filtered = customers.filter(name => name.cif.includes(query) && !whitelist.includes(name.cif));
        setCustomersFiltered(filtered);
    }

    const filterWhitelist = (query) => {
        setWhitelistQuery(query);
        if (query == "") {
            setWhitelistFiltered(whitelist);
        }
        else {
            var filtered = whitelist.filter(cif => cif.includes(query));
            setWhitelistFiltered(filtered);
        }
    }

    const handleWhitelistAdd = (cif, i) => {
        onWhitelistAdd(cif);
        var filtered = [...customersFiltered];
        filtered.splice(i, 1);
        setCustomersFiltered(filtered);
    }

    const handleWhitelistDelete = (cif, i) => {
        onWhitelistDelete(cif);
        var filtered = customersFiltered.filter(name => name.cif.includes(searchValue) && (!whitelist.includes(name.cif)));
        if (cif.includes(searchValue)) {
            filtered.push({ cif: cif, name: null, surname: null });
        }
        setCustomersFiltered(filtered);
    }

    return (
        <div>
            <div>
                <h3 className="section-heading">Whitelist</h3><br/>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "140px", gap: "20px" }}>
                    <div style={{ gridColumn: isPortrait? "span 12" : "span 1"}}>
                        <form className="search-form">
                            <label>Search CIF</label><br />
                            <input type="text" value={searchValue} onFocus={() => setWhitelistFocus(true)} className="whitelist-input" onChange={(e) => filterCustomers(e.target.value)} placeholder="Search CIF.." />
                        </form>
                        <ul className="whitelist-ul">
                            {whitelistFocus && customersFiltered.map((customer, i) => <li style={{ cursor: "pointer" }} onClick={() => { handleWhitelistAdd(customer.cif, i); }}><p>{customer.cif}</p></li>)}
                        </ul>
                    </div>
                    <div style={{ gridColumn: isPortrait? "span 12" : "span 1"}}>
                        <form className="search-form">
                            <label>Filter by CIF</label><br />
                            <input type="text" value={whitelistQuery} onFocus={() => setWhitelistFocus(true)} className="whitelist-input" onChange={(e) => filterWhitelist(e.target.value)} placeholder="Search Customers.." />
                        </form>
                    </div>
                </div>
            </div>

            {whitelistFiltered.length > 0 ?
                <ul className="whitelist-ul2">
                    {whitelistFiltered.map((cif, i) => <li><div style={{ background: i % 2 ? "#efefef" : "white", padding: "5px", display: "grid", gridTemplateColumns: "repeat(9, 1fr)", griAutoColumns: "140px", columnGap: "5%" }}><p style={{gridColumn: "span 8", textAlign: "left" }}>{cif}</p><button className="whitelist-delete-button" onClick={() => { handleWhitelistDelete(cif, i) }} style={{ background: "red", opacity: "0.6", gridColumn: "span 1" }}>X</button></div></li>)}
                </ul>
                : <p>No content</p>}
        </div>
    )
}

export default Whitelist;