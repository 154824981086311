import React from 'react';

const ConfirmPopup = ({title, subtitle, onConfirm, onCancel}) => {

    return (
      <div className="confirm-popup-parent">
        {/* Popup content */}
        <div className="popup-content">
          <h2>{title}</h2>
          <p>{subtitle}</p>

          {/* Buttons */}
          <div className="buttons">
            <button onClick={() => {onConfirm()}} className='popup-button popup-button-confirm'>Confirm</button>
            <button onClick={() => {onCancel()}} className='popup-button popup-button-cancel'>Cancel</button>
          </div>
        </div>
      </div>
    );
}

export default ConfirmPopup;