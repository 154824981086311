import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./context/AuthProvider";
import { NavProvider } from "./context/NavProvider";

import "./index.css";
import App from "./App";
import { OrientationProvider } from "./context/OrientationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <OrientationProvider>
        <NavProvider>
          <App />
        </NavProvider>
      </OrientationProvider>
    </AuthProvider>
  </React.StrictMode>
);
