import React, {useState, useEffect} from "react";

const TransTypeContainer = ({type}) => {

    const [colours, setColours] = useState({"2c2p": "#7B506F", "Open Banking": "#EF476F", "payout": "#98CE00", "smartswift": "#8EA4D2"});

    return (
        <div style={{textAlign: "center", background: colours[type], color: "white", padding: "5px 4px", borderRadius: "2px", fontWeight: "bold", width: "80px"}}>
            <p style={{fontSize: "13px"}}>{type}</p>
        </div>
    );
};

export default TransTypeContainer;