import { createContext, useState } from "react";

const OrientationContext = createContext({});

export const OrientationProvider = ({children}) => {
    const [isPortrait, setIsPortrait] = useState(false);

    return (
        <OrientationContext.Provider value={{isPortrait, setIsPortrait}}>
            {children}
        </OrientationContext.Provider>
    )
};

export default OrientationContext;