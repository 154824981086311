import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import Header from "../comonents/Header";
import useNav from "../hooks/useNav";
import { useNavigate } from "react-router-dom";
import useOrientation from "../hooks/useOrientation";
import ConfirmPopup from "./ConfirmPopup";



const Protected = () => {
    const {isPortrait} = useOrientation();
    const axiosPrivate = useAxiosPrivate();
    const [rules, setRules] = useState([]);
    const navigate = useNavigate();
    const { setPath } = useNav();
    const [popup, setPopup] = useState(false);
    const [deleteRuleId, setDeleteRuleId] = useState();
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();


        const getRules = async () => {
            try {
                const response = await axiosPrivate.get('/get-rules', {
                    signal: controller.signal
                });
                isMounted && setRules(response.data);
            }
            catch (error) {
                // console.error(error);
            }
        }
        getRules();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const handleDelete = async (rule_id) => {
        try {
            axiosPrivate.delete('/delete-rule', { data: { rule_id } }, {})
                .then((res) => {
                    var rulesUpdated = rules.filter(rule => rule.id !== rule_id);
                    setRules(rulesUpdated);
                });
        }
        catch (error) {

        };
        setPopup(false);
    }

    const handleEdit = (rule_id) => {
        setPath('/edit-rule?id=' + rule_id);
    };

    return (
        <div>
            {popup && <ConfirmPopup title={"Confirm Delete - "+deleteRuleId?.name} subtitle={"Are you sure you want to delete this rule?"} onConfirm={() => handleDelete(deleteRuleId?.id)} onCancel={() => {setPopup(false)}}/>}
            <Header title="Rules" subtitle="View and edit rules" />
            <div className={isPortrait?"main-portrait":"main"}><br/>
                <button className="blue-button" style={{marginBottom: "10px"}} onClick={() => {setPath('/add-rule')}}>Create New Rule</button>
                <div className="profile-section">
                    <div style={{overflowX: "auto", display: "block", padding: "20px"}}>
                        <table className="blueTable">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Whitelist</th>
                                    <th>Date Create</th>
                                    <th>Last Updated</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.map((rule, i) => <tr>
                                        <td>{rule?.id}</td>
                                        <td>{rule?.name}</td>
                                        <td>{rule?.description}</td>
                                        <td>{rule?.whitelist.length}</td>
                                        <td>{rule?.created}</td>
                                        <td>{rule?.last_updated}</td>
                                        <td><button style={{width: "70px"}} className="blue-button" onClick={() => {handleEdit(rule?.id)}}>Edit</button></td>
                                        <td><button style={{width: "70px"}} className="red-button" onClick={() => {setPopup(true) ; setDeleteRuleId({id:rule?.id, name:rule?.name})}}>Delete</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Protected;