import React from "react";
import "../css/Notifications.css";

const Notification = ({ from, to, date, opened, onRead, index }) => {

    return (<div className="notification-entry" onClick={() => { onRead(index) }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "140px", gap: "20px" }}>
            <div style={{ gridColumn: "span 2", borderRadius: "50%", background: opened ? "transparent" : "#EF476F", width: "20px", height: "20px", marginLeft: "auto", marginRight: "auto", marginTop: "50%" }}>
            </div>
            <div style={{ gridColumn: "span 10", fontSize: "11px", fontWeight: "bold", color: "#555555" }}>
                <p style={{fontSize: "13px", color: "rgb(15, 103, 137)"}}>Transaction to blacklisted beneficiary</p>
                <p>From: {from}</p>
                <p>To: {to}</p>
                <p>{date}</p>
            </div>

        </div>
    </div>
    )
};

export default Notification;