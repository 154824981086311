import React from "react";
import TransTypeContainer from "./TransTypeContainer";



const TransactionsTable = ({ transactions }) => {

    return (
        <div>
            <table className="blueTable">
                <thead>
                    <tr>
                        <th>CIF</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Amount Sent</th>
                        <th>Currency From</th>
                        <th>Currency To</th>
                        <th>Exchange Rate</th>
                        <th>Transaction Fee</th>
                        <th>Transaction Date</th>
                        <th>Type</th>
                    </tr>
                </thead>

                <tbody>
                    {transactions.map((tran) => (
                        <tr>
                            <td><a href={"/profile?cif=" + tran["Customer CIF"]}>{tran["Customer CIF"]}</a></td>
                            <td>{tran["Name"]}</td>
                            <td>{tran["Surname"]}</td>
                            <td>{tran["Amount Sent"]}</td>
                            <td>{tran["Currency From"]}</td>
                            <td>{tran["Currency To"]}</td>
                            <td>{tran["Exchange Rate"]}</td>
                            <td>{tran["Transaction Fee"]}</td>
                            <td>{tran["Transaction Date"]}</td>
                            <td><TransTypeContainer type={tran["Type"]} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
};

export default TransactionsTable;