import React, { useState, useEffect } from 'react';
import api from '../api';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Logout = () => {
    const [success, setSuccess] = useState(undefined);
    const { setAuth } = useAuth();


    const handleLogout = () => {
        api.delete('/logout-jwt',
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then((response) => {

        },
            (error) => {
                setSuccess(false);
            });
        setSuccess(true);
    }

    useEffect(() => {
        handleLogout();
    }, [])

    if (success === undefined) {
        return;
    }

    console.log("success: ", success);

    return success ? <Navigate to='/login' /> : <Navigate to='/list' />

};

export default Logout;