import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../comonents/Header";
import RuleTemplate from "./RuleTemplate";
import ConfirmPopup from "./ConfirmPopup";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useNav from "../hooks/useNav";
import useOrientation from "../hooks/useOrientation";

const EditRule = () => {
    const {isPortrait} = useOrientation();
    const [searchParams] = useSearchParams();
    const [ruleId, setRuleId] = useState();
    const [popup, setPopup] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const { setPath } = useNav();


    useEffect(() => {
        const id = searchParams.get('id');
        if (id !== null) {
            setRuleId(id);
        }
    }, []);

    const handleConfirm = () => {
        handleDelete();
    }

    const handleCancel = () => {
        setPopup(false);
    }

    const handleDelete = async () => {
        try {
            const response = await axiosPrivate.delete('/delete-rule', { data: { rule_id: ruleId } }, {})
                .then((res) => {
                    setPath('/rules');
                });
        }
        catch (error) {
            console.error(error);
        }
    }

    const handlePopup = () => {
        setPopup(true);
    }

    return (
        <div>
            {popup && <ConfirmPopup title="Confirm Delete" subtitle="Are you sure you want to delete this rule?" onConfirm={handleConfirm} onCancel={handleCancel} />}
            <Header title="Edit Rule" subtitle="Edit existing rule" /><br />
            <div className={isPortrait? "main-portrait" : "main"}>
                {ruleId &&
                    <div>
                        <RuleTemplate newRule={false} ruleId={ruleId} onPopup={handlePopup} />
                    </div>
                }
            </div>
        </div>
    )
};

export default EditRule;