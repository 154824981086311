import React, { useState, useEffect, useMemo } from "react";
import Header from "./Header";
import "../css/Profile.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useSearchParams } from "react-router-dom";
import LoadingCircle from "./LoadingCircle";
import TransTypeContainer from "./TransTypeContainer";
import useOrientation from "../hooks/useOrientation";
import ProfileSuggestionsPopup from "./ProfileSuggestionsPopup";

const Profile = () => {
    const { isPortrait } = useOrientation();
    const axiosPrivate = useAxiosPrivate();
    const [searchParams] = useSearchParams();
    const [cif, setCif] = useState('');
    const [registrationStartDate, setRegistrationStartDate] = useState();
    const [registrationEndDate, setRegistrationEndDate] = useState();

    const [searchedCif, setSearchedCif] = useState();

    const [customerFound, setCustomerFound] = useState(false);

    const [transType, setTransType] = useState('all');
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(false);

    const [statsLatest, setStatsLatest] = useState([]);
    const [latestCurrency, setLatestCurrency] = useState();
    const [loadingStatsLatest, setLoadingStatsLatest] = useState(false);

    const [stats, setStats] = useState([]);
    const [currency, setCurrency] = useState();
    const [loadingStats, setLoadingStats] = useState(false);

    const [customerOverview, setCustomerOverview] = useState(undefined);
    const [loadingCustomerOverview, setLoadingCustomerOverview] = useState(false);

    const [ruleData, setRuleData] = useState([]);
    const [loadingRuleData, setLoadingRuleData] = useState(false);

    const [rules, setRules] = useState([]);
    const [ruleId, setRuleId] = useState();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [currencyLatestOptions, setLatestCurrencyOptions] = useState([]);

    const [types, setTypes] = useState([]);

    const [name, setName] = useState();
    const [customerCode, setCustomerCode] = useState();

    const [suggestedCustomers, setSuggestedCustomers] = useState([]);

    const [lookupSuggestionsPopup, setLookupSuggestionsPopup] = useState(false);

    //On mount
    useEffect(() => {
        //Setting new date
        var start = new Date();
        var end = new Date();
        start.setMonth(end.getMonth() - 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(end.toISOString().split('T')[0]);

        const cif = searchParams.get('cif');
        const type = searchParams.get('type') ? searchParams.get('type') : transType;
        var start = new Date();
        start.setDate(1);
        start.setMonth(start.getMonth() - 1);
        var end = new Date(start.getFullYear(), start.getMonth(), 1);
        getTypes();
        getRules();

        if (cif) {
            setSearchedCif(cif);
            setCif(cif);
            getTransactions(cif, start, end);
            getStats(cif, start, end);
            getStatsLatest(cif);
            getOverview(cif);
        };
    }, []);

    const getRules = () => {
        axiosPrivate.get('/get-rules')
            .then((res) => {
                setRules(res.data);
            },
            (error) => {
                console.log("An error occured: ", error);
            }
        );
    }

    const getTypes = () => {
        axiosPrivate.get("/get-transaction-types")
            .then((res) => {
                setTypes(res.data);
                if(res.data.length > 0){
                    setTransType(res.data[0]);
                }
            }).
            then((error) => {

            })
    }


    const getOverview = (cif) => {
        setCustomerFound(false);
        setCustomerOverview(undefined);
        setLoadingCustomerOverview(true);
        axiosPrivate.get('/get-customer-overview?cif=' + cif)
            .then((res) => {
                if(res.data?.cif !== null){
                    setCustomerFound(true);
                }
                setCustomerOverview(res.data);
            },
                (error) => {

                });
        setLoadingCustomerOverview(false);
    }

    const currencySymbol = (currency) => {
        if (currency.toLowerCase() === 'gbp') {
            return '£'
        }
        else if (currency.toLowerCase() === 'eur') {
            return '€'
        }
    }

    const getStats = (cif, start, end) => {
        setLoadingStats(true);
        axiosPrivate.get('/get-customer-stats-v2?cif=' + cif + "&start=" + start + "&end=" + end)
            .then((res) => {
                setStats(res.data);
                let currencies = [];
                for (let curr in res.data['data']) {
                    currencies.push(curr);
                }
                setCurrencyOptions(currencies);
                if (currencies.length > 0) {
                    setCurrency(currencies[0]);
                }
            },
                (error) => {

                }
            );
        setLoadingStats(false);
    }

    const getStatsLatest = (cif) => {
        var start = new Date();
        var end = new Date();
        start.setDate(1);
        start = start.toISOString().split('T')[0];
        end = end.toISOString().split('T')[0];
        setLoadingStatsLatest(true);
        axiosPrivate.get('/get-customer-stats-v2?cif=' + cif + "&start=" + start + "&end=" + end)
            .then((res) => {
                setStatsLatest(res.data);
                let currencies = [];
                for (let curr in res.data['data']) {
                    currencies.push(curr);
                }
                setLatestCurrencyOptions(currencies);
            },
                (error) => {

                }
            );
        setLoadingStatsLatest(false);
    }

    useEffect(() => {
        if (rules.length > 0) {
            setRuleId(rules[0].id);
        }
    }, [rules]);

    useEffect(() => {
        if (ruleId && cif) getRuleData(ruleId, startDate, endDate);
    }, [ruleId]);

    const getRuleData = (ruleId, start, end) => {
        setRuleData([]);
        setLoadingRuleData(true);
        axiosPrivate.get('/get-rule-data-of-customer?rule_id=' + ruleId + "&start=" + start + "T00:00:00&end=" + end + "T00:00:00&cif=" + cif)
            .then((res) => {
                setRuleData(res.data);
                setLoadingRuleData(false);
            },
                (error) => {
                    setLoadingRuleData(false);
                    console.error(error);
                });
    }

    const getTransactions = (cif, start, end) => {
        setLoadingTransactions(true);
        axiosPrivate.get('/transaction_info?cif=' + cif + "&start=" + start + "T00:00:00&end=" + end + "T00:00:00")
            .then((res) => {
                setTransactions(res.data);
                setLoadingTransactions(false);
            },
                (error) => {
                    setLoadingTransactions(false);
                });
    }

    
    const searchCustomer = () => {
        setCustomerFound(false);
        axiosPrivate.get("/get-customer-overview?cif="+cif)
            .then((res) =>{
                if(res.data.cif !== null){
                    console.log("Customer Overview: ",res.data);
                    setCustomerFound(true);
                    setCustomerOverview(res.data);
                    getStatsAll();
                }
            },
            (error) => {

            }
        )
    };

    const getStatsAll = () => {
        getTransactions(cif, startDate, endDate);
        if(ruleId){
            getStats(cif, startDate, endDate);
            getStatsLatest(cif);
            getRuleData(ruleId, startDate, endDate);
        }
    }

    const handleLookupSuggestions = () => {
        axiosPrivate.get("/search-customers?name="+name+"&cif="+
            cif+ (registrationEndDate ? "&registrationStartDate="+registrationStartDate:"")+(registrationEndDate?"&registrationEndDate="+registrationEndDate:"")
                + (customerCode ? "&customerCode="+customerCode : "")
            )
            .then((res) => {
                setSuggestedCustomers(res.data);
                setLookupSuggestionsPopup(true);
            },
            (error) =>{

            }
        );


    }

    const handleSelectFromLookup = (customer) => {
        setSearchedCif(customer.account_cif);
        setCif(customer.account_cif);
        setLookupSuggestionsPopup(false);
    };

    useEffect(() => {
        handleSearch();
    }, [searchedCif])

    
    
    const handleSearch = () => {
        if(cif){
            searchCustomer();
        }
    };

    return (
        <div>
            {lookupSuggestionsPopup && <ProfileSuggestionsPopup onCancel={() => {setLookupSuggestionsPopup(false)}} suggestedCustomers={suggestedCustomers} onSelect={handleSelectFromLookup}/>}
            <Header title="Profile Page" subtitle="Welcome to the profile page" />
            <div className={isPortrait ? "main-portrait" : "main"}>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(24, 1fr)", gridAutoColumns: "140px", gap: isPortrait ? "20px 0px" : "20px" }}>
                    <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 12" }}>
                        <h3 className="section-heading">Search Customer</h3><br />
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                        <div style={{ gridColumn: "span 6" }}>
                                <form className="search-form">
                                    <label for="search-name"><strong>Name</strong></label><br />
                                    <input
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        className="input-field"
                                        type="text"
                                        name="search-name"
                                        id="search-name"
                                        placeholder="Name"
                                    />
                                </form>
                            </div>

                            <div style={{ gridColumn: "span 3" }}>
                                <form className="search-form">
                                    <label for="search-cif"><strong>CIF</strong></label><br />
                                    <input
                                        value={cif}
                                        onChange={(e) => { setCif(e.target.value) }}
                                        className="input-field"
                                        type="text"
                                        name="search-cif"
                                        id="search-cif"
                                        placeholder="CIF.."
                                    />
                                </form>
                            </div>

                            <div style={{ gridColumn: "span 3" }}>
                                <form className="search-form">
                                    <label for="serch-customer-code"><strong>Customer Code</strong></label><br />
                                    <input
                                        value={customerCode}
                                        onChange={(e) => { setCustomerCode(e.target.value) }}
                                        className="input-field"
                                        type="text"
                                        name="serch-customer-code"
                                        id="serch-customer-code"
                                        placeholder="Customer Code.."
                                    />
                                </form>
                            </div>

                            <div style={{ gridColumn: "span 3" }}>
                                <form className="search-form">
                                    <label for="search-registration-start"><strong>Registration Date Start</strong></label><br />
                                    <input
                                        value={registrationStartDate}
                                        onChange={(e) => { setRegistrationStartDate(e.target.value) }}
                                        className="input-field"
                                        type="date"
                                        name="search-registration-start"
                                        id="search-registration-start"
                                        placeholder="From.."
                                    />
                                </form>
                            </div>
                            <div style={{ gridColumn: "span 3" }}>
                                <form className="search-form">
                                    <label for="search-registration-end"><strong>Registration Date End</strong></label><br />
                                    <input
                                        value={registrationEndDate}
                                        onChange={(e) => { setRegistrationEndDate(e.target.value) }}
                                        className="input-field"
                                        type="date"
                                        name="search-registration-end"
                                        id="search-registration-end"
                                        placeholder="To.."
                                    />
                                </form>
                            </div>
                            <div style={{ gridRowStart: "5", gridColumn: " span 6" }}>
                                <button className={"blue-button"} onClick={() => { handleLookupSuggestions() }} style={{ width: "100%" }}>Lookup</button>
                            </div>
                        </div>
                    </div>

                    {customerFound && 
                        <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 6" }}>
                            <h3 className="section-heading">Search Transaction Period</h3><br />
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                                <div style={{ gridColumn: "span 12" }}>
                                    <form className="search-form">
                                        <label for="start-date"><strong>Start Date</strong></label><br />
                                        <input
                                            value={startDate}
                                            onChange={(e) => { setStartDate(e.target.value) }}
                                            className="input-field"
                                            type="date"
                                            name="start-date"
                                            id="start-date"
                                            placeholder="Start Date"
                                        />
                                    </form>
                                </div>
                                <div style={{ gridColumn: "span 12" }}>
                                    <form className="search-form">
                                        <label for="end-date"><strong>End Date</strong></label><br />
                                        <input
                                            value={endDate}
                                            onChange={(e) => { setEndDate(e.target.value) }}
                                            className="input-field"
                                            type="date"
                                            name="end-date"
                                            id="end-date"
                                            placeholder="End Date"
                                        />
                                    </form>
                                </div>
                                <div style={{ gridRowStart: "5", gridColumn: " span 12" }}>
                                    <button className={"black-button"} onClick={() => { getStatsAll() }} style={{ width: "100%" }}>Search</button>
                                </div>
                            </div>
                        </div>
                    }
                    {customerFound && 
                        <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 12" }}>
                            <h3 className="section-heading">Overview</h3><br />
                            {loadingCustomerOverview && <LoadingCircle />}
                            {!loadingCustomerOverview && !customerOverview && <p>Customer not found</p>}
                            {!loadingCustomerOverview && customerOverview &&
                                <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridAutoColumns: "100%" }}>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Customer Name</strong>
                                        <p>{customerOverview?.name} {customerOverview?.sname}</p>
                                    </div>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Customer Code</strong>
                                        <p>{customerOverview?.customer_code}</p>
                                    </div>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Last Login</strong>
                                        <p>{customerOverview?.last_login}</p>
                                    </div>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Registration Date</strong>
                                        <p>{customerOverview?.registration_date}</p>
                                    </div>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Most Sent To</strong>
                                        <p>{customerOverview?.most_active_receiver}</p>
                                    </div>
                                    <div style={{ gridColumn: "span 1" }}>
                                        <strong>Status Code</strong>
                                        <p>{customerOverview?.status}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {customerFound && 
                        <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 6" }}>
                            <h3 className="section-heading">Last 30 days</h3><br />
                            {loadingStatsLatest && <LoadingCircle />}
                            {!loadingStatsLatest && statsLatest &&
                                <div>
                                    <select style={{ borderStyle: "None", marginBottom: "10px" }} onChange={(e) => { setLatestCurrency(e.target.value) }}>
                                        {currencyLatestOptions.map((currency) => (<option value={currency}>{currency}</option>))}
                                    </select>
                                    <div style={{ borderStyle: "solid", borderColor: "#ddd", borderWidth: "thin", padding: "5px", display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Mean</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Std Dev</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong># of Trans</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Total</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Type</strong>
                                        </div>
                                        {currencyLatestOptions.includes(latestCurrency) && statsLatest[latestCurrency].map((entry) => (
                                            <>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(latestCurrency)}{entry?.mean}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(latestCurrency)}{entry?.standard_deviation}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{entry?.no_of_trans}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(latestCurrency)}{entry?.sum}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{entry?.type}</p>
                                                </div>
                                            </>))}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {customerFound && 
                        <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 6" }}>
                            <h3 className="section-heading">{startDate} - {endDate}</h3><br />
                            {loadingStats && <LoadingCircle />}
                            {!loadingStats && stats &&
                                <div>
                                    <select style={{ borderStyle: "None", marginBottom: "10px" }} onChange={(e) => { setCurrency(e.target.value) }}>
                                        {currencyOptions.map((currency) => (<option value={currency}>{currency}</option>))}
                                    </select>
                                    <div style={{ borderStyle: "solid", borderColor: "#ddd", borderWidth: "thin", padding: "5px", display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Mean</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Std Dev</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong># of Trans</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Total</strong>
                                        </div>
                                        <div style={{ gridColumn: "span 1" }}>
                                            <strong>Type</strong>
                                        </div>
                                        {currencyOptions.includes(currency) && stats['data'][currency].map((entry) => (
                                            <>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(currency)}{entry?.mean}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(currency)}{entry?.standard_deviation}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{entry?.no_of_trans}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <p>{currencySymbol(currency)}{entry?.sum}</p>
                                                </div>
                                                <div style={{ gridColumn: "span 1" }}>
                                                    <TransTypeContainer type={entry?.type} />
                                                </div>
                                            </>))}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {customerFound && 
                        <div className="profile-section" style={{ gridColumn: isPortrait ? "span 24" : "span 10", overflowY: "scroll" }}>
                            <h3 className="section-heading">Transactions</h3><br />
                            {loadingTransactions && <LoadingCircle />}
                            {!loadingTransactions &&
                                <div>
                                    <label for="rule">Choose a transaction type:</label><br />
                                    <select value={transType} onChange={(e) => { setTransType(e.target.value) }} className="input-field" name="rules" id="rule-select">
                                        <option value="">Please choose an option</option>
                                        <option value="all">All</option>
                                        {/* <option value="2c2p">2C2P</option>
                                    <option value="topup">Topup</option>
                                    <option value="payout">Payout</option>
                                    <option value="smartswift">Smartswift</option> */}
                                        {types.map((type) => (<option value={type}>{type}</option>))}
                                    </select><br /><br />
                                    <table className="blueTable" style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Currency</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <td>foot1</td>
                                                <td>foot2</td>
                                                <td>foot3</td>
                                                <td>foot4</td>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {transactions.map((tran) => ((transType === "all" || tran['Type'] === transType) &&
                                                <tr>
                                                    <td>{tran['Transaction Date']}</td>
                                                    <td>{tran['Amount Sent']}</td>
                                                    <td>{tran['Currency From']}</td>
                                                    <td><TransTypeContainer type={tran['Type']} /></td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    }

                    {customerFound && 
                        <div className="profile-section" style={{ height: "600px", gridColumn: isPortrait ? "span 24" : "span 14", overflowY: "scroll", height: "400px" }}>
                            <h3 className="section-heading">Rules Broken</h3><br />
                            {loadingRuleData && <LoadingCircle />}
                            {rules.length === 0 && !loadingRuleData && <p>No rules found</p>}
                            {rules.length > 0 && !loadingRuleData && <div>
                                <label for="rules">Choose a rule</label><br />
                                <select onChange={(e) => { setRuleId(e.target.value) }} value={ruleId} className="input-field" name="rules" id="rule-select">
                                    <option value="">Please choose an option</option>
                                    {rules.map((rule) => (<option value={rule.id}>{rule.name}</option>))}
                                </select><br /><br />
                                <table className="blueTable" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Week Start</th>
                                            <th>Total Amount Sent</th>
                                            <th># of Trans</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ruleData.map((tran) => (
                                            <tr>
                                                <td>{tran['Start Date']}</td>
                                                <td>{tran['End Date']}</td>
                                                <td>{tran['Week Start']}</td>
                                                <td>{tran['Total Amount']}</td>
                                                <td>{tran['# of Trans']}</td>
                                                <td><TransTypeContainer type={tran['Type']} /></td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    }


                </div>
            </div>
        </div>
    )
};

export default Profile;