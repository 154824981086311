import React, { useState, useEffect } from "react";
import Header from "./Header";
import LoadingCircle from "./LoadingCircle";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ResponsiveBar } from "@nivo/bar";
import useNav from "../hooks/useNav";
import "../css/Dashboard.css";
import useOrientation from "../hooks/useOrientation";
import TransactionsTable from "./TransactionsTable";


const Dashboard = () => {
    const { isPortrait } = useOrientation();
    const axiosPrivate = useAxiosPrivate();

    const [graphData, setGraphData] = useState();
    const [loadingGraph, setLoadingGraph] = useState(false);

    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(false);

    const [rules, setRules] = useState([]);
    const { path, setPath } = useNav();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [rulesBroken, setRulesBroken] = useState();

    const [groupBy, setGroupBy] = useState('week');
    const [isMounted, setIsMounted] = useState(false);



    useEffect(() => {
        var startTmp = new Date();
        var endTmp = new Date();
        startTmp.setMonth(endTmp.getMonth() - 1);
        startTmp.setDate(startTmp.getDate())
        startTmp = dateToString(startTmp);
        endTmp = dateToString(endTmp);
        setStartDate(startTmp);
        setEndDate(endTmp);

        getRuleViolationsAll(startTmp, endTmp);
        getLatestTransactions();
        getRules();
        setIsMounted(true);

    }, []);

    const getRuleViolationsAll = (start, end) => {
        setLoadingGraph(true);
        setRulesBroken();
        axiosPrivate.get('/get-rule-violations-all-rules?start=' + start + 'T00:00:00&end=' + end + 'T00:00:00')
            .then((res) => {
                setRulesBroken(res.data);
                setLoadingGraph(false);
            },
                (error) => {
                    setLoadingGraph(false);
                    console.log("Something went wrong")
                }
            )
    }

    useEffect(() => {
        if (rules && rulesBroken && rulesBroken.length > 0 && rules.length > 0) {
            generateGraphData(rulesBroken);
        }
    }, [rulesBroken]);

    useEffect(() => {
        if (isMounted && rulesBroken) {
            generateGraphData(rulesBroken);
        };
    }, [groupBy, rulesBroken])

    const generateGraphData = (violations) => {
        let data = {}
        let bar_data = {}
        let start = new Date(startDate);
        let end = new Date(endDate);
        let dict = violations?.rules;
        for (let rule in dict) {
            data[rule] = 0;
        };
        while (start < end) {
            let week = new Date(start);
            if (groupBy == "week") {
                week.setDate(week.getDate() - week.getDay() + 1);
            }
            let week_data = { ...data };
            week = week.toISOString().split('T')[0]
            bar_data[week] = week_data;
            switch (groupBy) {
                case 'day':
                    start.setDate(start.getDate() + 1);
                    break
                case 'week':
                    start.setDate(start.getDate() - start.getDay() + 7);
                    break;
                default:
                    start.setDate(start.getDate() - start.getDay() + 7);
                    break;
            }
        };
        for (let v in violations['violations']) {
            const violation = violations['violations'][v];
            let date2 = new Date();
            switch (groupBy) {
                case 'day':
                    date2 = new Date(violation['Rule Violation Date']);
                    break;
                case 'week':
                    date2 = new Date(violation['Rule Violation Week']);
                    break;
                default:
                    date2 = new Date(violation['Rule Violation Week']);
                    break;

            }
            bar_data[date2.toISOString().split('T')[0]][violation['Rule Name']] += 1;
        };
        let bar_data2 = { 'bar': [], 'keys': violations?.keys, 'dict': dict };
        for (let b in bar_data) {
            let bar_data_tmp = { ...bar_data[b] };
            bar_data_tmp['date'] = b;
            bar_data2['bar'].push(bar_data_tmp);
        };
        setGraphData(bar_data2);
    }

    const getRules = () => {
        axiosPrivate.get('/get-rules')
            .then((res) => {
                setRules(res.data);
            },
                (error) => {

                }
            );
    }

    const getLatestTransactions = () => {
        setTransactionsLoading(true);
        axiosPrivate.get("/transaction_info?limit=10")
            .then((res) => {
                setTransactions(res.data);
                setTransactionsLoading(false);
            },
                (error) => {
                    setTransactionsLoading(false);
                }
            );
    }

    function dateToString(date) {
        return date.toISOString().split('T')[0];
    }

    const handleSearch = () => {
        getRuleViolationsAll(startDate, endDate);
    };

    const handleRefresh = () => {
        getLatestTransactions();
    }


    return (
        <div>
            <Header title="Dashboard" subtitle="Dashboard" />
            <div className={isPortrait ? "main-portrait" : "main"}>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "140px", gap: "20px" }}>

                    <div className="profile-section" style={{ gridColumn: isPortrait ? "span 12" : "span 3" }}>
                        <h3>Search Options</h3><br />
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                            <div style={{ gridColumn: "span 6" }}>
                                <form className="search-form">
                                    <label for="start-date"><strong>Start Date</strong></label><br />
                                    <input
                                        value={startDate}
                                        type="date"
                                        placeholder="Start Date"
                                        className="input-field"
                                        id="start-date"
                                        name="start-date"
                                        onChange={(e) => { setStartDate(e.target.value) }}
                                    />
                                </form>
                            </div>

                            <div style={{ gridColumn: "span 6" }}>
                                <form className="search-form">
                                    <label for="end-date"><strong>End Date</strong></label><br />
                                    <input
                                        value={endDate}
                                        type="date"
                                        placeholder="End Date"
                                        className="input-field"
                                        id="end-date"
                                        name="end-date"
                                        onChange={(e) => { setEndDate(e.target.value) }}
                                    />
                                </form>
                            </div>

                            <div style={{ gridRowStart: "5", gridColumn: "span 6" }}>
                                <button className="blue-button" onClick={() => { handleSearch() }} style={{ width: "100%" }}>Search</button>
                            </div>
                            <div style={{ gridRowStart: "5", gridColumn: "span 6" }}>
                                <button className="black-button-inverse" onClick={() => { handleRefresh() }} style={{ width: "100%" }}>Refresh</button>
                            </div>
                        </div>
                    </div>
                    <div className="profile-section" style={{ gridColumn: isPortrait ? "span 12" : "span 9", maxHeight: "400px", overflowX: "auto", display: "block"}}>
                        <h3>Bar Chart</h3><br />
                        {loadingGraph && <LoadingCircle />}
                        {!loadingGraph &&
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                                <div style={{ gridColumn: "span 3" }}>
                                    <form className="search-form">
                                        <label for="group-by"><strong>Group by</strong></label><br />
                                        <select defaultValue="week" className="input-field" onChange={(e) => { setGroupBy(e.target.value) }}>
                                            <option value="day">
                                                Day
                                            </option>
                                            <option value="week">
                                                Week
                                            </option>
                                            <option value="month">
                                                Month
                                            </option>
                                        </select>
                                    </form>
                                </div>
                            </div>
                        }
                        {graphData && !loadingGraph &&
                            <div style={{height: "350px", minWidth: "600px"}}>
                            <ResponsiveBar
                                onClick={(data) => { setPath('/alerts-and-notifications?rule_id=' + graphData?.dict[data?.id] + "&start=" + startDate + "&end=" + endDate) }}
                                data={graphData?.bar}
                                theme={{
                                    axis: {
                                        domain: {
                                            line: {
                                                fill: "#000",
                                                fontSize: 12,
                                                fontWeight: 'bold'
                                            }
                                        },
                                        legend: {
                                            text: {
                                                fill: "#000",
                                                fontSize: 12,
                                                fontWeight: 'bold'
                                            }
                                        },
                                        ticks: {
                                            line: {
                                                fill: "#000",
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                strokeWidth: 1
                                            },
                                            text: {
                                                fill: "#000",
                                                fontSize: 12,
                                                fontWeight: 'bold'
                                            }
                                        }
                                    },
                                    legends: {
                                        text: {
                                            fill: "#000",
                                            fontSize: 12,
                                            fontWeight: 'bold'
                                        }
                                    }
                                }}
                                keys={graphData?.keys}
                                indexBy="date"
                                margin={{ top: 50, right: 200, bottom: 150, left: 30 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}

                                groupMode="grouped"

                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}

                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: 32,
                                    tickRotation: 45,  // Rotate the labels to prevent overlap
                                    tickValues: 'every 2 days'  // Adjust this value as needed to reduce the number of ticks displayed

                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}

                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in date: " + e.indexValue }}
                            />
                            </div>
                        }
                    </div>

                    <div className="profile-section" style={{ gridColumn: "span 12", overflowX: "auto", display: "block"}}>
                        <h3>Latest Transactions</h3>
                        {transactionsLoading && <LoadingCircle />}
                        {!transactionsLoading &&
                            <div style={{ padding: "20px", fontSize: "13px", height: "75%" }}>
                                <TransactionsTable transactions={transactions} />
                                <button className="black-button" style={{ width: "15%", marginTop: "10px" }} onClick={() => { setPath('/transactions') }}>View All Transactions</button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Dashboard;