import React, { useState, useEffect } from "react";
import Header from "./Header";
import useOrientation from "../hooks/useOrientation";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoadingCircle from "./LoadingCircle";



const Customers = () => {

    const { isPortrait } = useOrientation();
    const [customers, setCustomers] = useState({});
    const [customersLoading, setCustomersLoading] = useState();

    const axiosPrivate = useAxiosPrivate();
    const [page, setPage] = useState(0);
    const [cif, setCif] = useState();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();

    const [country, setCountry] = useState();

    const [registrationDateStart, setRegistrationDateStart] = useState();
    const [registrationDateEnd, setRegistrationDateEnd] = useState();

    const [statusOptions, setStatusOptions] = useState(['All']);
    const [status, setStatus] = useState("All");

    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        getCustomersAll();
        setMounted(true);
    }, []);

    const handleSearch = () => {
        if (page === 0) {
            getCustomers();
        }
        setPage(0);
    };

    useEffect(() => {
        if (mounted) {
            getCustomers();
        }
    }, [page])

    const handleClearSearch = () => {
        setCif("");
        setCountry("");
        setFirstName("");
        setLastName("");
        setRegistrationDateStart();
        setRegistrationDateEnd();
        setCountry("");
        setStatus("");
        setEmail("");
        setPhoneNumber("");
        setPage(0);
        if (page === 0) {
            getCustomersAll();
        }
    }

    function appendQueryParamToUrl(url, field, value, unpermittedValues) {
        if (!unpermittedValues.includes(value)) {
            return url += "&" + field + "=" + value;
        }
        else {
            return url;
        }
    };

    const changePage = (page) => {
        setPage(page);
    }

    const getCustomersAll = () => {
        setCustomersLoading(true);
        axiosPrivate.get("/get-customers?page=0")
            .then((res) => {
                setCustomers(res.data);
                setCustomersLoading(false);
            },
                (error) => {
                    setCustomersLoading(false);
                }
            )
    }

    const getCustomers = () => {
        setCustomersLoading(true);
        setCustomers([]);
        let url = "/get-customers?page=" + page;

        url = appendQueryParamToUrl(url, "account_cif", cif, ["", undefined]);
        url = appendQueryParamToUrl(url, "email", email, ["", undefined]);
        url = appendQueryParamToUrl(url, "full_mobile_no", phoneNumber, ["", undefined]);
        url = appendQueryParamToUrl(url, "name", firstName, ["", undefined]);
        url = appendQueryParamToUrl(url, "sname", lastName, ["", undefined]);
        url = appendQueryParamToUrl(url, "country", country, ["", undefined]);
        url = appendQueryParamToUrl(url, "registration_date_from", registrationDateStart, ["", undefined]);
        url = appendQueryParamToUrl(url, "registration_date_to", registrationDateEnd, ["", undefined]);
        url = appendQueryParamToUrl(url, "status", status, ["", undefined, "All"]);

        axiosPrivate.get(url)
            .then((res) => {
                setCustomers(res.data);
                setCustomersLoading(false);
            },
                (error) => {
                    console.error("Something went wrong: ", error);
                    setCustomersLoading(false);
                });
    }

    return (
        <div>
            <Header title="Customers" subtitle="Welcome to the customer page" />
            <div className={isPortrait ? "main-portrait" : "main"}>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(12, 1fr)",
                        gridAutoColumns: "100%",
                        gap: "10px",
                    }}
                >
                    <div className="profile-section" style={{ gridColumn: "span 12" }}>
                        <h3>Search Options</h3>
                        <br />
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(12, 1fr)",
                                gridAutoColumns: "100%",
                                gap: "20px",
                            }}
                        >
                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="cif"><strong>Customer CIF</strong></label>
                                <br />
                                <input
                                    value={cif}
                                    className="input-field"
                                    placeholder="CIF"
                                    name="cif"
                                    id="cif"
                                    type="text"
                                    max="51515"
                                    onChange={(e) => {
                                        setCif(e.target.value);
                                    }}
                                ></input>
                            </div>
                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="first-name"><strong>First Name</strong></label>
                                <br />
                                <input
                                    value={firstName}
                                    className="input-field"
                                    placeholder="First Name.."
                                    name="first-name"
                                    id="first-name"
                                    type="text"
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                ></input>
                            </div>
                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="last-name"><strong>Last Name</strong></label>
                                <br />
                                <input
                                    value={lastName}
                                    className="input-field"
                                    placeholder="Last Name.."
                                    name="last-name"
                                    id="last-name"
                                    type="text"
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="email"><strong>Email</strong></label>
                                <br />
                                <input
                                    value={email}
                                    className="input-field"
                                    placeholder="Email.."
                                    name="email"
                                    id="email"
                                    type="text"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="phone"><strong>Phone Number</strong></label>
                                <br />
                                <input
                                    value={phoneNumber}
                                    className="input-field"
                                    placeholder="Phone Number.."
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="country"><strong>Country</strong></label>
                                <br />
                                <input
                                    className="input-field"
                                    placeholder="Country"
                                    name="country"
                                    id="country"
                                    type="text"
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="registration-date-from"><strong>Registration Date From</strong></label>
                                <br />
                                <input
                                    value={registrationDateStart}
                                    className="input-field"
                                    placeholder="From,,"
                                    name="registration-date-from"
                                    id="registration-date-from"
                                    type="date"
                                    onChange={(e) => {
                                        setRegistrationDateStart(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="registration-date-to"><strong>Registration Date To</strong></label>
                                <br />
                                <input
                                    value={registrationDateEnd}
                                    className="input-field"
                                    placeholder="To,,"
                                    name="registration-date-to"
                                    id="registration-date-to"
                                    type="date"
                                    onChange={(e) => {
                                        setRegistrationDateEnd(e.target.value);
                                    }}
                                ></input>
                            </div>

                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                                <label for="status"><strong>Status</strong></label>
                                <br />
                                <select value={status} className="input-field" onChange={(e) => { setStatus(e.target.value) }}>
                                    {statusOptions.map((res) => (<option value={status}>{res}</option>))}
                                </select>
                            </div>
                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3", gridRow: isPortrait ? "6" : "4" }}>
                                <button className="blue-button" style={{ width: "100%" }} onClick={() => { handleSearch() }}>
                                    Search
                                </button>
                            </div>
                            <div style={{ gridColumn: isPortrait ? "span 6" : "span 3", gridRow: isPortrait ? "6" : "4" }}>
                                <button className="black-button" style={{ width: "100%" }} onClick={() => { handleClearSearch() }}>
                                    Clear Search
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="profile-section" style={{ gridColumn: "span 12", overflowX: "auto", display: "block" }}>
                        <h3>Customers</h3>
                        <br />
                        {customersLoading && <LoadingCircle />}
                        {!customersLoading && 'customers' in customers && customers['customers'].length === 0 &&
                            <p>No customers found</p>
                        }
                        {!customersLoading && 'customers' in customers && customers['customers'].length > 0 &&
                            <div>
                                <table className="blueTable">
                                    <tfoot>
                                        {'pages' in customers &&
                                            <tr>

                                                <td colSpan="7">
                                                    <div className="links">
                                                        {customers['pages'].map((pg) => (
                                                            <a style={{ color: pg === page ? "white" : "", background: pg === page ? "#19A8E0" : "" }} onClick={() => { changePage(pg) }}>{pg}</a>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                    <thead>
                                        <tr>
                                            <th>Customer CIF</th>
                                            <th>Full Name</th>
                                            <th>Country</th>
                                            <th>KYC Result</th>
                                            <th>POA Result</th>
                                            <th>Registration Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        {'pages' in customers &&
                                            <tr>

                                                <td colSpan="7">
                                                    <div className="links">
                                                        {customers['pages'].map((pg) => (
                                                            <a style={{ color: pg === page ? "white" : "", background: pg === page ? "#19A8E0" : "" }} onClick={() => { changePage(pg) }}>{pg}</a>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                    <tbody>
                                        {'customers' in customers && customers['customers'].map((customer) => (
                                            <tr>
                                                <td><a href={"/profile?cif=" + customer['cif']}>{customer['cif']}</a></td>
                                                <td>{customer['name']} {customer['sname']}</td>
                                                <td>{customer['country']}</td>
                                                <td>{customer['kyc_result']}</td>
                                                <td>{customer['proof_result']}</td>
                                                <td>{customer['registration_date']}</td>
                                                <td>{customer['status']}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    )

};

export default Customers;