import Header from "./Header";
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useOrientation from "../hooks/useOrientation";
import TransTypeContainer from "./TransTypeContainer";
import LoadingCircle from "./LoadingCircle";
import Select from 'react-select';
import { useSearchParams } from "react-router-dom";


const RulesBroken = () => {


  const axiosPrivate = useAxiosPrivate();
  const { isPortrait } = useOrientation();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingRules, setLoadingRules] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rules, setRules] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('All');
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedRule, setSelectedRule] = useState([]);

  useEffect(() => {
    let start = new Date();
    let end = new Date();
    if (searchParams.get('start') && searchParams.get('end')) {
      start = new Date(searchParams.get('start'))
      end = new Date(searchParams.get('end'))
    }
    else {
      start.setMonth(start.getMonth() - 1);
    }
    setStartDate(dateToString(start));
    setEndDate(dateToString(end));
    getRules();
    getTransactionTypes();
  }, []);

  const getTransactionTypes = () => {
    axiosPrivate.get('/get-transaction-types')
      .then((res) => {
        console.log("Types: ", res.data);
        setTypes(res.data);
      },
        (error) => {

        }
      )
  }


  const [rulesArray, setRulesArray] = useState([]); // To use with .map and Select
  const [rulesMap, setRulesMap] = useState({}); // For quick lookup by ID

  const getRules = () => {
    setLoadingRules(true);
    axiosPrivate.get('/get-rules')
      .then((res) => {
        const rulesArray = res.data;
        setRulesArray(rulesArray);

        // Create a map for easy lookup
        const rulesMap = {};
        rulesArray.forEach(rule => {
          rulesMap[rule.id] = rule.name; // or use rule.description if that's what you want to display
        });

        setRulesMap(rulesMap); // Save this map in the state

        let selectedRules = rulesArray.map((rule) => ({ 'label': rule?.id + ": " + rule?.description, 'value': rule?.id }));
        if (searchParams.get('rule_id')) {
          selectedRules = selectedRules.filter(rule => rule?.value == searchParams.get('rule_id'));
        }
        setSelectedRule(selectedRules);
        setLoadingRules(false);
      },
        (error) => {
          setLoadingRules(false);
          setIsMounted(true);
        });
  };


  useEffect(() => {
    if (selectedRule.length && !isMounted) {
      setIsMounted(true);
      getAllRulesData();
    }
  }, [selectedRule]);

  const getAllRulesData = () => {
    const promises = [];
    setLoadingData(true);
    selectedRule.map((rule) => {
      const result = axiosPrivate.get("/get-rule-data?rule_id=" + rule?.value + '&start=' + startDate + "T00:00:00&end=" + endDate + "T00:00:00").then(
        (res) => {
          return res.data;
        },
        (error) => {
        }
      );
      promises.push(result);
    });
    let dataTmp = [];
    Promise.all(promises).then((values) => {
      for (let val in values) {
        dataTmp = dataTmp.concat(values[val]);

      }
      setData(dataTmp);
      setLoadingData(false);
    })
  }

  function dateToString(date) {
    return date.toISOString().split('T')[0];
  };

  const handleSearch = () => {
    getAllRulesData();
  };

  return (
    <div>
      <Header title="Alerts & Notifications" subtitle="Look at broken rules" />
      <div className="main">
        <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "140px", gap: "20px" }}>
          <div className="profile-section" style={{ gridColumn: isPortrait ? "span 12" : "span 12" }}>
            <h3>Search Options</h3><br />
            <div style={{ display: "grid", gridTemplateColumns: "repeat(24, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>

              <div style={{ gridColumn: "span 24" }}>
                <form className="search-form">
                  <label for="rule"><strong>Select which rules to search for</strong></label><br />
                  <Select
                    value={selectedRule}
                    isMulti
                    options={rulesArray.map((rule) => ({ 'label': rule?.id + ": " + rule?.description, 'value': rule?.id }))}
                    onChange={(e) => { setSelectedRule(e) }}
                  >
                  </Select>
                </form>
              </div>

              <div style={{ gridColumn: isPortrait ? "span 6" : "span 3", gridRow: "2" }}>
                <form className="search-form">
                  <label for="start-date"><strong>Start Date</strong></label><br />
                  <input
                    value={startDate}
                    type="date"
                    placeholder="Start Date"
                    className="input-field"
                    id="start-date"
                    name="start-date"
                    onChange={(e) => { setStartDate(e.target.value) }}
                  />
                </form>
              </div>

              <div style={{ gridColumn: isPortrait ? "span 6" : "span 3", gridRow: "2" }}>
                <form className="search-form">
                  <label for="end-date"><strong>End Date</strong></label><br />
                  <input
                    value={endDate}
                    type="date"
                    placeholder="End Date"
                    className="input-field"
                    id="end-date"
                    name="end-date"
                    onChange={(e) => { setEndDate(e.target.value) }}
                  />
                </form>
              </div>

              <div style={{ gridRowStart: "5", gridColumn: isPortrait ? "span 12" : "span 6" }}>
                <button disabled={loadingData} className="blue-button" onClick={() => { handleSearch() }} style={{ width: "100%" }}>Search</button>
              </div>
            </div>
          </div>

          <div className="profile-section" style={{ gridColumn: isPortrait ? "span 12" : "span 12", display: "block", overflowX: "auto", whiteSpace: "nowrap" }}>
            <h3>Rules Broken Data</h3><br />
            {loadingData && <LoadingCircle />}
            {!loadingData &&
              <div style={{ display: "grid", gridTemplateColumns: "repeat(24, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                <div style={{ gridColumn: isPortrait ? "span 6" : "span 3" }}>
                  <form className="search-form">
                    <label for="type-filter"><strong>Type</strong></label><br />
                    <select
                      className="input-field"
                      onChange={(e) => { setSelectedType(e.target.value) }}
                    >
                      <option key="All" value="All">All</option>
                      {types.map((type) => (<option key={type} value={type}>{type}</option>))}
                    </select>
                  </form>
                </div>
              </div>
            }
            {!loadingData &&
              <table className="blueTable">
                <thead>
                  <tr>
                    <th>Customer CIF</th>
                    <th>Currency From</th>
                    <th>Total Amount Sent</th>
                    <th># of Trans</th>
                    <th>First Transaction Date</th>
                    <th>Rule Violation Date</th>
                    <th>Type</th>
                    <th>Rule</th>
                  </tr>
                </thead>
                <tbody>
                  {data.filter((entry) => selectedType === entry['Type'] || selectedType === 'All')
                    .sort((first, second) => new Date(second['Week Start']) - new Date(first['Week Start']))
                    .map((entry) => (
                      <tr key={entry["Customer_CIF"] + entry["Rule"]}>
                        <td><a href={"/profile?cif=" + entry["Customer_CIF"]}>{entry["Customer_CIF"]}</a></td>
                        <td>{entry["Transaction Currency"]}</td>
                        <td>{entry["Total Sent"]}</td>
                        <td>{entry["# Of Trans"]}</td>
                        <td>{entry["First Transction Date"]}</td>
                        <td>{entry["Rule Violation Date"]}</td>
                        <td><TransTypeContainer type={entry["Type"]} /></td>
                        <td>{rulesMap[entry['Rule']]}</td> {/* Use rulesMap to get the name */}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default RulesBroken;
