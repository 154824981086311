import {Navigate, Outlet, useLocation} from "react-router-dom";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";


export const ProtectedRoute = () => {
    const [authorized, setAuthorized] = useState(undefined);
    const {auth} = useAuth();
    const location = useLocation();
    const refresh = useRefreshToken();

    if(authorized === undefined){
        if(auth?.access_token === undefined){ //refresh token if it is not defined
            refresh().then(res => {
                setAuthorized(true);
            },
            (error) => {
                // console.error(error);
                setAuthorized(false);
            }
            );
        }
        else{
            setAuthorized(true);
        }
    }

    if(authorized === undefined){
        return null;
    }

    return authorized ? <Outlet/> : <Navigate to="/login"/>
};