import { useContext, useEffect } from "react";
import AuthContext  from "../context/AuthProvider";

const useAuth = () =>{
    const {auth, setAuth} = useContext(AuthContext);

    useEffect(() => {
    }, [])
    return {auth, setAuth};
}

export default useAuth;