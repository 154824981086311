import React, { useEffect, useState } from "react";
import Header from "./Header";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import TransactionsTable from "./TransactionsTable";

const Transactions = () => {
  const axiosPrivate = useAxiosPrivate();

  const [cif, setCif] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [transactions, setTransactions] = useState([]);
  
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  useEffect(() => {
    console.log("Mounting");
    getTypes();
    getTransactions();
  }, []);

  const getTransactions = () => {
    axiosPrivate.get("/transaction_info?limit=20").then(
      (res) => {
        setTransactions(res.data);
      },
      (error) => {}
    );
  };

  const getTypes = () => {
    axiosPrivate.get("/get-transaction-types").then(
      (res) => {
        setTypes(res.data);
      },
      (error) => {}
    );
  };

  const getCurrencies = () => {
    axiosPrivate.get("/get-transaction-types").then(
      (res) => {
        setTypes(res.data);
      },
      (error) => {}
    );
  };

  function dateToString(date) {
    return date.toISOString().split('T')[0] + "T00:00:00";
    }

  useEffect(() => {
    if(month != undefined && year != undefined){
        var start = new Date(year, month, 1);
        var end = new Date(year, month, 1);
        end.setMonth(start.getMonth() + 1);
        setStartDate(dateToString(start));
        setEndDate(dateToString(end));
    }
  }, [month, year])

  const handleSearch = () => {
    var endpoint = '/transaction_info?limit=20'
    endpoint += cif ? '&cif='+cif : "";
    endpoint += selectedType ? '&type='+selectedType : '';
    endpoint += startDate ? '&start='+startDate : '';
    endpoint += endDate ? '&end='+endDate : '';
    endpoint += currency ? '&currency='+currency : '';
    axiosPrivate.get(endpoint)
        .then((res) => {
            setTransactions(res.data);
        },
        (error) => {

        }
        )
  }

  return (
    <div>
      <Header title="Transactions" subtitle="View transaction history" />
      <div className="main">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridAutoColumns: "100%",
            gap: "10px",
          }}
        >
          <div className="profile-section" style={{ gridColumn: "span 6" }}>
            <h3>Search Options</h3>
            <br />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(24, 1fr)",
                gridAutoColumns: "100%",
                gap: "20px",
              }}
            >
              <div style={{ gridColumn: "span 6" }}>
                <label for="cif">Customer CIF</label>
                <br />
                <input
                  className="input-field"
                  placeholder="CIF"
                  name="cif"
                  id="cif"
                  type="text"
                  max="51515"
                  onChange={(e) => {
                    setCif(e.target.value);
                  }}
                ></input>
              </div>
              <div style={{ gridColumn: "span 6" }}>
                <label for="type">Transaction Type</label>
                <br />
                <select
                  className="input-field"
                  id="type"
                  name="type"
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                >
                  <option value="all">All</option>
                  {types.map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </div>

              <div style={{ gridColumn: "span 3" }}>
                <label for="currency">Currency</label>
                <br />
                <select className="input-field" id="currency" name="currency" onChange={(e) => setCurrency(e.target.value)}>
                  <option value="all">All</option>
                  <option value="gbp">GBP</option>
                  <option value="eur">EUR</option>
                  <option value="thb">THB</option>
                </select>
              </div>

              <div style={{ gridColumn: "span 4" }}>
                <label for="month">Month</label>
                <br />
                <select value={month} className="input-field" id="month" name="month" onChange={(e) => {setMonth(e.target.value)}}>
                  <option value={0}>Jan</option>
                  <option value={1}>Feb</option>
                  <option value={2}>Mar</option>
                  <option value={3}>Apr</option>
                  <option value={4}>May</option>
                  <option value={5}>Jun</option>
                  <option value={6}>Jul</option>
                  <option value={7}>Aug</option>
                  <option value={8}>Sep</option>
                  <option value={9}>Oct</option>
                  <option value={10}>Nov</option>
                  <option value={11}>Dec</option>
                </select>
              </div>
              <div style={{ gridColumn: "span 4" }}>
                <label for="year">Year</label>
                <br />
                <select value={year} className="input-field" id="year" name="year" onChange={(e) => {setYear(e.target.value)}}>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>
              <div style={{ gridColumn: "span 12" }}>
                <button className="blue-button" style={{ width: "100%" }} onClick={() => {handleSearch()}}>
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="profile-section" style={{ gridColumn: "span 12" }}>
            <h3>Transactions</h3>
            <br />
            <TransactionsTable transactions={transactions}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
