import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "../css/RuleTemplate.css";
import useNav from "../hooks/useNav";
import ConfirmPopup from "./ConfirmPopup";
import '../css/ConfirmPopup.css';
import '../css/Whitelist.css';
import Whitelist from "./Whitelist";
import "../css/Profile.css";
import "../css/RuleTemplate.css";
import useOrientation from "../hooks/useOrientation";





const RuleTemplate = ({ newRule, ruleId, onPopup}) => {
    const { setPath } = useNav();
    const {isPortrait} = useOrientation();
    const [searchParams] = useSearchParams();
    const axiosPrivate = useAxiosPrivate();
    const [rule, setRule] = useState(undefined);
    const [ruleName, setRuleName] = useState(undefined);
    const [maxPerPeriod, setMaxPerPeriod] = useState(undefined);
    const [newCustomer, setNewCustomer] = useState(0);
    const [amountOrFreq, setAmountOrFreq] = useState(0);
    const [period, setPeriod] = useState(1);
    const [periodUnit, setPeriodUnit] = useState('day');
    const [whitelist, setWhitelist] = useState([]);

    const [validRuleName, setValidRuleName] = useState(true);
    const [validMaxPerPeriod, setValidMaxPerPeriod] = useState(true);
    const [validNewCustomer, setValidNewCustomer] = useState(true);
    const [validAmountOrFreq, setValidAmountorFreq] = useState(true);
    const [validPeriod, setValidPeriod] = useState(true);
    const [validPeriodUnit, setValidPeriodUnit] = useState(true);

    const [ruleNameFocus, setRuleNameFocus] = useState(false);
    const [maxPerPeriodFocus, setMaxPerPeriodFocus] = useState(false);
    const [periodFocus, setPeriodFocus] = useState(false);

    const[description, setDescription] = useState();

    const [popup, setPopup] = useState(false);
    const [detectIfLessWasSent, setDetectIfLessWasSent] = useState(false);


    const setFieldsInvalidAll = () => {
        setValidRuleName(false);
        setValidMaxPerPeriod(false);
        setValidPeriod(false);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getRule = async (ruleId) => {
            try {
                const response = await axiosPrivate.get('/get-rule', {
                    signal: controller.signal,
                    params: {
                        rule_id: ruleId
                    }
                });
                if (isMounted) {
                    const data = response.data;
                    setRule(data);
                    setRuleName(data.name);
                    setMaxPerPeriod(data.max_per_period);
                    setNewCustomer(data.new_customer);
                    setAmountOrFreq(data.number);
                    setPeriod(data.period);
                    setPeriodUnit(data.period_unit);
                    setWhitelist(data.whitelist);
                    setDetectIfLessWasSent(data.detect_if_less_was_sent);
                }
            }
            catch (error) {
                console.error(error);
            }
        }
        if (newRule) {
            setFieldsInvalidAll();
        }
        else {
            getRule(ruleId);
        }
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);


    useEffect(() => {
        if(validMaxPerPeriod && validPeriod){
            let description = (Number(detectIfLessWasSent) ? 'Less than ' : 'More than ')+ maxPerPeriod + (amountOrFreq ? " transactions" : " GBP") + " sent within " + period + " " + periodUnit + (period === 1 ? "" : "s");
            description += (newCustomer ? " by new customers" : "");
            setDescription(description);
        }
        else{
            setDescription();
        }
    }, [period, periodUnit, amountOrFreq, maxPerPeriod, newCustomer, validMaxPerPeriod, validPeriod, detectIfLessWasSent]);


    const handleChangeRuleName = (value) => {
        const REGEX = /^[a-zA-Z0-9_ ]{3,25}$/;
        const WHITESPACE = /^\s/;
        if (!WHITESPACE.test(value) && REGEX.test(value)) {
            setValidRuleName(true);
        }
        else {
            setValidRuleName(false);
        }
        setRuleName(value);
    }

    const handleChangeMaxPerPeriod = (value) => {
        if (value > 100000 || value <= 0) {
            setValidMaxPerPeriod(false);
        }
        else {
            setValidMaxPerPeriod(true);
        }
        setMaxPerPeriod(value);
    }

    const handleChangeIsNewCustomer = (value) => {
        setNewCustomer(parseInt(value))
    }

    const handleChangeAmountOrFreq = (value) => {
        setAmountOrFreq(parseInt(value))
    }

    const handleChangePeriod = (value) => {
        if (value > 25) {
            setValidPeriod(false);
        }
        else {
            setValidPeriod(true);
        }
        if(value === '' || value <= 0){
            setPeriod(1);
        }
        else{
            setPeriod(parseInt(value));
        }
    }

    const handleChangePeriodUnit = (value) => {
        setPeriodUnit(value);
    }

    const handleSubmitEditRule = async () => {
        const ruleUpdated = {
            rule_id: searchParams.get('id'),
            max_per_period: maxPerPeriod,
            name: ruleName,
            new_customer: newCustomer,
            number: amountOrFreq,
            period: period,
            period_unit: periodUnit,
            whitelist: whitelist,
            detect_if_less_was_sent: Number(detectIfLessWasSent)
        }
        axiosPrivate.put('/update-rule', ruleUpdated)
            .then((res) => {
                setPath('/rules');
            },
                (error) => {
                    console.error(error);
                }
            )
    }

    const handleSubmitNewRule = async () => {
        const newRule = {
            max_per_period: maxPerPeriod,
            name: ruleName,
            new_customer: newCustomer,
            number: amountOrFreq,
            period: period,
            period_unit: periodUnit,
            whitelist: whitelist,
            detect_if_less_was_sent: Number(detectIfLessWasSent)
        }
        axiosPrivate.post('/create-rule', newRule)
            .then((res) => {
                setPath('/rules');
            },
                (error) => {
                    console.error(error);
                }
            )
    }

    const invalidInput = (fieldValid) => {
        if (!fieldValid) {
            return 'invalid-field'
        }
        return '';
    }

    //Style label with error if corresponding input field is invalid
    const invalidInputLabel = (fieldValid) => {
        if (!fieldValid) {
            return (
                {
                    color: 'red',
                    opacity: '0.7'
                }
            )
        }

        return {};
    }

    const isValid = () => {
        return (validAmountOrFreq && validMaxPerPeriod && validNewCustomer && validPeriod && validPeriodUnit && validRuleName) ? true : false;
    }

    const popupConfirmSaveChanges = () => {
        newRule ? handleSubmitNewRule() : handleSubmitEditRule();
    }

    const popupCancelSaveChanges = () => {
        setPopup(false);
    }

    const handleWhitelistAdd = (cif) => {
        if (whitelist.includes(cif)) {
            return;
        }
        setWhitelist([
            ...whitelist,
            cif
        ]);
    }

    const handleWhitelistDelete = (index) => {
        var whitelistRemove = [...whitelist]
        whitelistRemove = whitelist.filter(cif => cif != index);
        setWhitelist(whitelistRemove);
    };

    /*TODO Make whitelist section of the page */
    return (
        <div>
            {popup && <ConfirmPopup title={newRule ? "Confirm New Rule" : "Confirm Changes"} subtitle={newRule ? "Create new rule" : `Save changes to rule "${ruleName}"`} onConfirm={popupConfirmSaveChanges} onCancel={popupCancelSaveChanges} />}

            <div style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gridAutoColumns: "140px", gap: isPortrait? "20px 0x" : "20px" }}>
                <div className="profile-section2" style={{ gridColumn: isPortrait? "span 12" : "span 3"}}>
                    <h3 className="section-heading">Rule Details</h3><br/>
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridAutoColumns: "100%", gap: "10px" }}>
                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <form className="search-form">
                                <label style={invalidInputLabel(validRuleName)} for="rule-name"><strong>Rule Name</strong></label><br />
                                <input
                                    className={`input-field ${invalidInput(validRuleName)}`}
                                    type="text"
                                    name="rule-name"
                                    placeholder="Rule Name"
                                    value={ruleName}
                                    onChange={(e) => handleChangeRuleName(e.target.value)}
                                    onFocus={() => setRuleNameFocus(true)}
                                    onBlur={() => setRuleNameFocus(false)}
                                />
                                <p className={(ruleNameFocus && !validRuleName) ? "instructions" : "offscreen"}>
                                    Only characters and numbers allowed.<br />
                                    3 - 25 characters <br />
                                    Must start with number or character
                                </p>
                            </form>
                        </div>
                        <div style={{gridColumn: isPortrait? "span 4" : "span 4"}}>
                            <label style={invalidInputLabel(validPeriod)} for="period"><strong>What period of time to look between transactions?</strong></label>
                        </div>
                        <div style={{ gridColumn: isPortrait?"span 1": "span 1" }}>
                            <form className="search-form">
                                <input
                                    className={`input-field ${invalidInput(validPeriod)}`}
                                    type="number"
                                    name="period"
                                    min="1"
                                    max="25"
                                    placeholder="Period"
                                    value={period}
                                    onChange={(e) => handleChangePeriod(e.target.value)}
                                    onBlur={() => { setPeriodFocus(false)}}
                                    onFocus={() => { setPeriodFocus(true) }}
                                />
                                <p className={(periodFocus && !validPeriod) ? "instructions" : "offscreen"}>
                                    Must be between 1 and 25
                                </p>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 3": "span 3" }}>
                            <form className="search-form">
                                <label style={invalidInputLabel(validPeriodUnit)} for="period-unit"><strong></strong></label>
                                <select onChange={(e) => handleChangePeriodUnit(e.target.value)}
                                    className='input-field'
                                    id='period-unit'
                                    name='period-unit'
                                    value={periodUnit}
                                >
                                    <option value="hour">Hour</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                </select>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <form className="search-form">
                                <label style={invalidInputLabel(validAmountOrFreq)} for="amount-or-freq"><strong>Look at 'Amount Sent' or '# of Transactions'</strong></label>
                                <select onChange={(e) => handleChangeAmountOrFreq(e.target.value)}
                                    className='input-field'
                                    id='amount-or-freq'
                                    name='amount-or-freq'
                                    value={amountOrFreq}
                                >
                                    <option value="0">Amount sent</option>
                                    <option value="1"># of transactions</option>
                                </select>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <label style={invalidInputLabel(validMaxPerPeriod)} for="max-per-period"><strong>{amountOrFreq?"# of transactions":" Total send amount"} allowed within {period} {periodUnit}</strong></label>
                        </div>
                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <form className="search-form">
                                <input
                                    className={`input-field ${invalidInput(validMaxPerPeriod)}`}
                                    type="number"
                                    name="max-per-period"
                                    placeholder="Amount"
                                    min="1"
                                    max="100000"
                                    value={maxPerPeriod}
                                    onChange={(e) => handleChangeMaxPerPeriod(e.target.value)}
                                    onFocus={() => setMaxPerPeriodFocus(true)}
                                    onBlur={() => setMaxPerPeriodFocus(false)}
                                />
                                <p className={(maxPerPeriodFocus && !validMaxPerPeriod) ? "instructions" : "offscreen"}>
                                    Must be between 1 and 100,000
                                </p>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <label style={invalidInputLabel(validMaxPerPeriod)} for="max-per-period"><strong>Detect if more than or less than this amount was sent</strong></label>
                        </div>
                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <form className="search-form">
                                <select value={detectIfLessWasSent} className="input-field" onChange={(e) => {setDetectIfLessWasSent(e.target.value)}}>
                                    <option value="0">More Than</option>
                                    <option value="1">Less Than</option>
                                </select>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <form className="search-form">
                                <label style={invalidInputLabel(validNewCustomer)} for="new-customer"><strong>Apply rule to all customers or new customers only?</strong></label>
                                <select onChange={(e) => handleChangeIsNewCustomer(e.target.value)}
                                    className='input-field'
                                    id='new-customer'
                                    name='new-customer'
                                    value={newCustomer}
                                >
                                    <option value="0">All customers</option>
                                    <option value="1">Only new customers</option>
                                </select>
                            </form>
                        </div>

                        <div style={{ gridColumn: isPortrait?"span 4": "span 4" }}>
                            <strong>Description</strong>
                            <div className="description-container">
                                <p>
                                    {description}
                                </p>
                            </div>
                        </div>
                        
                        <div style={{ gridColumn: isPortrait?"span 2": "span 2" }}>
                            <button style={{width: "100%"}} className={"blue-button " + (isValid() ? "" : "disabled")} disabled={!isValid()} onClick={() => setPopup(true)}>{newRule ? "Create" : "Save Changes"}</button>
                        </div>
                        <div style={{ gridColumn: isPortrait?"span 2": "span 2" }}>
                            <button style={{width: "100%"}} onClick={() => { setPath('/rules') }} className="black-button-inverse">Cancel</button>
                        </div>
                        {!newRule && 
                            <div style={{ gridColumn: isPortrait?"span 2": "span 2" }}>
                                <button style={{width: "100%"}} className="red-button" onClick={() => {onPopup()}}>Delete</button><br/><br/>
                            </div>
                        }
                    </div>
                </div>
                {whitelist &&
                    <div className="whitelist-section" style={{ gridColumn: "span 12", maxWidth: "2000px"}}>
                        <Whitelist whitelist={whitelist} onWhitelistAdd={handleWhitelistAdd} onWhitelistDelete={handleWhitelistDelete} />
                    </div>
                }
            </div>
        </div>
    )
};

export default RuleTemplate;