import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
const BASE_URL = 'https://s2.transactionmonitor.co.uk';

const api = axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if(token){
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry){
            originalRequest._retry = true;

            try {
                const response = await axios.post('https://s2.transactionmonitor.co.uk/refresh', {},
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
                const {access_token} = await response.data;

                localStorage.setItem('accessToken', access_token);
                originalRequest.headers.Authorization = `Bearer ${access_token}`;
                return axios(originalRequest);
            }
            catch(error){
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        }
        else{
        }
        return Promise.reject(error);
    }
);

export default api;