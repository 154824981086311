import { useContext, useEffect} from "react";
import NavContext  from "../context/NavProvider";
import { useNavigate } from "react-router-dom";


const useNav = () =>{
    const navigate = useNavigate();
    const {path, setPath} = useContext(NavContext);

    useEffect(() =>{
        if(path === '/logout'){ //Special case to navigate to 
            navigate('/logout');
            setPath();
        }
        else{
            navigate(path);
        }
    }, [path])

    return {path, setPath};
}

export default useNav;