import React from "react";
import Header from "./Header";
import RuleTemplate from "./RuleTemplate";
import useOrientation from "../hooks/useOrientation";

const AddRule = () => {
    const {isPortrait} = useOrientation();

    return (
        <div>
            <Header title="Add Rule" subtitle="Welcome to add rule"/>
            <div className={isPortrait? "main-portrait" : "main"}>
                <RuleTemplate newRule={true} ruleId={undefined}/>
            </div>
        </div>
    )
};

export default AddRule;