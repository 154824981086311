import React, {useEffect, useState} from "react";
import { Outlet, createSearchParams, useSearchParams} from "react-router-dom";
import "../css/Sidebar.css";
import { ReactComponent as Logo } from "../images/KogoFlower.svg";
import useNav from "../hooks/useNav";
import useOrientation from "../hooks/useOrientation";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = () => {
    const {isPortrait, setIsPortrait} = useOrientation();
    const {path, setPath} = useNav();
    const [searchParams] = useSearchParams();
    const [expandSidebar, setExpandSidebar] = useState(false);


    useEffect(() => {
        const params = {}
        for(let entry of searchParams.entries()){
            params[entry[0]] = entry[1];
        }

        setPath({pathname: window.location.pathname,
            search: createSearchParams(params).toString()
        });

        checkIsPortrait();

        const handleResize = () =>{
            if(window.innerHeight > window.innerWidth || window.innerWidth < 1200) {
                setIsPortrait(true);
            }
            else{
                setIsPortrait(false);
            }

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkIsPortrait = () => {
        if(window.innerHeight > window.innerWidth || window.innerWidth < 1200) {
            setIsPortrait(true);
        }
    }

    const handleNavigate = (pathname) => {
        setExpandSidebar(false);
        setPath(pathname);
    };


    const getOptionClass = (pathname) =>{
        return path === pathname ? 'selected': '';
    }

    const getSidebarClass = () => {
        return "sidenav" + (isPortrait ? " sidenav-portrait" : "");
    }


    return (
        <div>
            <div className={getSidebarClass()} style={{width: (isPortrait && expandSidebar) ? "100%" : ""}}>
                {isPortrait &&
                    <div style={{display: "flex", justifyContent: "center", alignItems:"center", marginBottom: "20px"}}>
                        <FontAwesomeIcon 
                            className={"expand "+(expandSidebar?"fa-rotate-90":"")} 
                            icon={faBars} 
                            style={{width: "30px", height: "30px", color: "black"}} 
                            onClick={() => {setExpandSidebar(!expandSidebar)}}
                        />
                    </div>
                }
                <div style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                    <Logo />
                </div>
                {(!isPortrait || (isPortrait && expandSidebar)) && 
                    <div>
                        <h1 style={{textAlign: "center", color: "black"}}>AML Monitoring</h1>
                        <a className={getOptionClass('/dashboard')} onClick={() => handleNavigate('/dashboard')}>Dashboard</a>
                        <a className={getOptionClass('/rules')} onClick={() => handleNavigate('/rules')}>Rules</a>
                        <a className={getOptionClass('/profile')} onClick={() => handleNavigate('/profile')}>Profile</a>
                        <a className={getOptionClass('/transactions')} onClick={() => handleNavigate('/transactions')}>Transactions</a>
                        <a className={getOptionClass('/alerts-and-notifications')} onClick={() => handleNavigate('/alerts-and-notifications')}>Alerts & Notifications</a>
                        <a className={getOptionClass('/customers')} onClick={() => handleNavigate('/customers')}>Customers</a>
                        <a onClick={() => {handleNavigate('/logout')}}>Logout</a>
                    </div>
                }
            </div>
            <div>
                <Outlet/>
            </div>
        </div>
    )
}

export default Sidebar;