import { useContext, useEffect } from "react";
import OrientationContext from "../context/OrientationProvider";

const useOrientation = () =>{
    const {isPortrait, setIsPortrait} = useContext(OrientationContext);

    useEffect(() => {
    }, [])
    return {isPortrait, setIsPortrait};
}

export default useOrientation;