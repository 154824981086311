import './App.css';
import Login from './comonents/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from './routes/ProtectedRoute';
import Logout from './comonents/Logout';
import Protected from './comonents/Protected';
import Sidebar from './comonents/Sidebar';
import EditRule from './comonents/EditRule';
import AddRule from './comonents/AddRule';
import Profile from './comonents/Profile';
import Dashboard from './comonents/Dashboard';
import Transactions from './comonents/Transactions';
import Customers from './comonents/Customers';
import RulesBroken from './comonents/RulesBroken';



function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route element={<ProtectedRoute/>}>
            <Route element={<Sidebar/>}>
              <Route index element={<Dashboard/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="rules" element={<Protected/>} />
              <Route path="edit-rule" element={<EditRule/>} />
              <Route path="add-rule" element={<AddRule/>} />
              <Route path="profile" element={<Profile/>} />
              <Route path="transactions" element={<Transactions/>} />
              <Route path="customers" element={<Customers/>} />
              <Route path="alerts-and-notifications" element={<RulesBroken/>} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
