import { createContext, useState } from "react";

const NavContext = createContext({});

export const NavProvider = ({children}) => {
    const [path, setPath] = useState({});

    return (
        <NavContext.Provider value={{path, setPath}}>
            {children}
        </NavContext.Provider>
    )
};

export default NavContext;