import React from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import { useNavigate } from 'react-router-dom';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const refresh = async () => {
        const response = await axios.post('https://s2.transactionmonitor.co.uk/refresh', {},
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })

            setAuth(prev => {
                return {...prev, access_token: response.data.access_token}
            });
            return response.data.access_token
    }
    return refresh;
}

export default useRefreshToken;