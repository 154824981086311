import useOrientation from "../hooks/useOrientation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import React, { useState, setState, useEffect, useRef } from "react";
import Notification from "./Notification";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Mp3 from "../sfx/notification_sound.mp3";

const Header = ({ title, subtitle }) => {
    const { isPortrait } = useOrientation();
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [alert, setAlert] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const interval = useRef([]);

    const getClass = () => {
        return isPortrait ? " main-portrait" : "main"
    };

    const soundAlert = () => {
        let audio = new Audio(Mp3);
        audio.play().then((res) => {
        },
            (error) => {
            }
        )
    }

    const newNotification = () => {
        setAlert(true);
        soundAlert();
    }


    useEffect(() => {
        if (notifications.length > 0 && interval.current.length > 0 && notifications[0]['id'] !== interval.current[0]['id']) {
            newNotification();
        }
        interval.current = notifications;
        setAlert(false);
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i]['opened'] === 0) {
                setAlert(true);
                break;
            }
        };
    }, [notifications]);

    useEffect(() => {
        updateNotifications();
        // setInterval(updateNotifications, 30000);
    }, [])


    const handleRead = (index) => {
        if (notifications[index]['opened'] === 0) {
            let newNotifications = [...notifications];
            newNotifications[index]['opened'] = 1;
            setNotifications(newNotifications);
            axiosPrivate('/mark-as-read?id=' + notifications[index]['id'])
                .then((res) => {
                },
                    (error) => {

                    }
                )
        }
    };

    const updateNotifications = () => {
        axiosPrivate('/get-flagged-trans').then((res) => {
            setNotifications(res.data);
        },
            (error) => {
                console.log("Error occured: ", error)
            }
        )
    }

    return (
        <div>
            <div className={getClass()} style={{ display: "grid", alignItems: "center", background: "white", height: "114px", marginBottom: "20px", boxShadow: "-10px -4px 10px 1px #888888" }}>
                <h2>{title}</h2>
                <div style={{ padding: "5px", marginLeft: "auto", marginRight: "0", background: showNotifications ? "#19A8E0" : "none", borderRadius: "50%", alignContent: "center", width: "25px", height: "25px" }}>
                    {alert &&
                        <div style={{ padding: "3px", marginLeft: "auto", borderRadius: "50%", width: "3px", height: "3px", background: "red" }}></div>
                    }
                    <FontAwesomeIcon onClick={() => { setShowNotifications(!showNotifications) }} style={{ color: showNotifications ? "white" : "", width: "100%", height: "100%" }} icon={faBell} />
                </div>
            </div>
            {showNotifications &&
                <div style={{ position: "relative" }}>
                    <div style={{
                        border: "solid 1px #CCCCCC",
                        borderRadius: "5px",
                        minWidth: "300px",
                        minHeight: "300px",
                        right: "10px",
                        position: "absolute",
                        zIndex: "99 !important",
                        background: "white"
                    }}
                    >
                        <h3 style={{ padding: "10px" }}>Notifications</h3>
                        {notifications.length === 0 && <p>No new notifications</p>}
                        {notifications.map((entry, i) => (<Notification key={i} onRead={handleRead} index={i} from={entry['from']} to={entry['to']} date={entry['date']} opened={entry['opened']} />))}
                    </div>
                </div>
            }
        </div>
    )
}

export default Header;